<template src="./customerlogin.html"></template>
<style scoped src="./customerlogin.css"></style>

<script>
import Loading from 'vue-loading-overlay'
import applicationConstants from '@/constants/AppConstants.vue'
import reportServices from '@/services/ReportService.vue'
import Modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils'
import moment from 'moment'
import JsonToExcel from 'vue-json-excel'
import Pagination from '@/components/vtable/vtpagination.vue'


export default {
    name: "CustomerLogin",
    components: {
        Loading, Modal, Pagination, JsonToExcel
    },
    data () {
        return {
            email: null,
            dateFrom: null,
            dateTo: null,
            loginTableResponseData: [],
            loginTableDataColumns: ['Email', 'LoginDate'],
            loginTableOptions: {},
            enablePaginateData: applicationConstants.enableLazyLoading,
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            csvFields: {
                "Email" : "Email",
                "LoginDate": "LoginDate"
            }
        }
    },
    methods: {
        getRecentLogin () {
            this.loaderVisible = true
            let fDate = this.dateFrom ? (utils.methods.LocalToUTC(moment(moment(this.dateFrom)._d).format('MM-DD-YYYY hh:mm:ss'))) : null 
            let tDate = this.dateTo ? (utils.methods.LocalToUTC(moment(moment(this.dateTo)._d).format('MM-DD-YYYY hh:mm:ss'))) : null 
            reportServices.methods.GetCustomerRecentLogins({
               Email: this.email ? this.email : null,
               DateFrom: fDate,
               DateTo: tDate
            })
            .then(response => {
                this.loginTableResponseData = response.csGetCustomerRecentLoginResponse.LoginData?.RptLoginData
                this.loginTableResponseData = this.loginTableResponseData ? (Array.isArray(this.loginTableResponseData) ? this.loginTableResponseData: [this.loginTableResponseData]) : []
                if (this.enablePaginateData) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
                this.loaderVisible = false
            })
            .catch(err => {
                this.loginTableResponseData = []
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        reponseModifier (loginData) {
            if (loginData) {
                loginData.forEach(login => {
                    Object.keys(login).map(key => login[key] = login[key]?._text ? login[key]._text : null)
                })
            }
            return loginData ? loginData : []
        },
        clearAll () {
            this.email = null
            this.dateFrom = null
            this.dateTo = null
        },
        exportTableData () {
            if (this.loginTableResponseData.length > 0) {
                return this.reponseModifier(JSON.parse(JSON.stringify(this.loginTableResponseData)))
            } 
        }
    },
    computed: {
        loginTableData () {
            let customerLoginData = []
            if (this.loginTableResponseData && this.loginTableResponseData.length > 0) {
                customerLoginData = JSON.parse(JSON.stringify(this.loginTableResponseData))
                if(this.enablePaginateData) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    customerLoginData = customerLoginData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                customerLoginData = this.reponseModifier(JSON.parse(JSON.stringify(customerLoginData)))
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.loginTableResponseData?.length ?? 0
                    }
                }
                if (this.enablePaginateData) {
                    updateTotalRecords();
                }
            }
            return customerLoginData
        }
    }
}
</script>